/* https://github.com/highlightjs/highlight.js/blob/master/src/styles/atom-one-light.css */
/* BSD 3-Clause License https://github.com/highlightjs/highlight.js/blob/master/LICENSE */

/*
Atom One Light by Daniel Gamage
Original One Light Syntax theme from https://github.com/atom/one-light-syntax
base:    #fafafa
mono-1:  #383a42
mono-2:  #686b77
mono-3:  #a0a1a7
hue-1:   #0184bb
hue-2:   #4078f2
hue-3:   #a626a4
hue-4:   #50a14f
hue-5:   #e45649
hue-5-2: #c91243
hue-6:   #986801
hue-6-2: #c18401
*/

.atom-one-light .hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #383a42;
  background: #fafafa;
}

.atom-one-light .hljs-comment,
.atom-one-light .hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}

.atom-one-light .hljs-doctag,
.atom-one-light .hljs-keyword,
.atom-one-light .hljs-formula {
  color: #a626a4;
}

.atom-one-light .hljs-section,
.atom-one-light .hljs-name,
.atom-one-light .hljs-selector-tag,
.atom-one-light .hljs-deletion,
.atom-one-light .hljs-subst {
  color: #e45649;
}

.atom-one-light .hljs-literal {
  color: #0184bb;
}

.atom-one-light .hljs-string,
.atom-one-light .hljs-regexp,
.atom-one-light .hljs-addition,
.atom-one-light .hljs-attribute,
.atom-one-light .hljs-meta-string {
  color: #50a14f;
}

.atom-one-light .hljs-built_in,
.atom-one-light .hljs-class .hljs-title {
  color: #c18401;
}

.atom-one-light .hljs-attr,
.atom-one-light .hljs-variable,
.atom-one-light .hljs-template-variable,
.atom-one-light .hljs-type,
.atom-one-light .hljs-selector-class,
.atom-one-light .hljs-selector-attr,
.atom-one-light .hljs-selector-pseudo,
.atom-one-light .hljs-number {
  color: #986801;
}

.atom-one-light .hljs-symbol,
.atom-one-light .hljs-bullet,
.atom-one-light .hljs-link,
.atom-one-light .hljs-meta,
.atom-one-light .hljs-selector-id,
.atom-one-light .hljs-title {
  color: #4078f2;
}

.atom-one-light .hljs-emphasis {
  font-style: italic;
}

.atom-one-light .hljs-strong {
  font-weight: bold;
}

.atom-one-light .hljs-link {
  text-decoration: underline;
}

/* https://github.com/highlightjs/highlight.js/blob/master/src/styles/atom-one-dark.css */
/* BSD 3-Clause License https://github.com/highlightjs/highlight.js/blob/master/LICENSE */

/*
Atom One Dark by Daniel Gamage
Original One Dark Syntax theme from https://github.com/atom/one-dark-syntax
base:    #282c34
mono-1:  #abb2bf
mono-2:  #818896
mono-3:  #5c6370
hue-1:   #56b6c2
hue-2:   #61aeee
hue-3:   #c678dd
hue-4:   #98c379
hue-5:   #e06c75
hue-5-2: #be5046
hue-6:   #d19a66
hue-6-2: #e6c07b
*/

.atom-one-dark .hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #abb2bf;
  background: #282c34;
}

.atom-one-dark .hljs-comment,
.atom-one-dark .hljs-quote {
  color: #5c6370;
  font-style: italic;
}

.atom-one-dark .hljs-doctag,
.atom-one-dark .hljs-keyword,
.atom-one-dark .hljs-formula {
  color: #c678dd;
}

.atom-one-dark .hljs-section,
.atom-one-dark .hljs-name,
.atom-one-dark .hljs-selector-tag,
.atom-one-dark .hljs-deletion,
.atom-one-dark .hljs-subst {
  color: #e06c75;
}

.atom-one-dark .hljs-literal {
  color: #56b6c2;
}

.atom-one-dark .hljs-string,
.atom-one-dark .hljs-regexp,
.atom-one-dark .hljs-addition,
.atom-one-dark .hljs-attribute,
.atom-one-dark .hljs-meta-string {
  color: #98c379;
}

.atom-one-dark .hljs-built_in,
.atom-one-dark .hljs-class .hljs-title {
  color: #e6c07b;
}

.atom-one-dark .hljs-attr,
.atom-one-dark .hljs-variable,
.atom-one-dark .hljs-template-variable,
.atom-one-dark .hljs-type,
.atom-one-dark .hljs-selector-class,
.atom-one-dark .hljs-selector-attr,
.atom-one-dark .hljs-selector-pseudo,
.atom-one-dark .hljs-number {
  color: #d19a66;
}

.atom-one-dark .hljs-symbol,
.atom-one-dark .hljs-bullet,
.atom-one-dark .hljs-link,
.atom-one-dark .hljs-meta,
.atom-one-dark .hljs-selector-id,
.atom-one-dark .hljs-title {
  color: #61aeee;
}

.atom-one-dark .hljs-emphasis {
  font-style: italic;
}

.atom-one-dark .hljs-strong {
  font-weight: bold;
}

.atom-one-dark .hljs-link {
  text-decoration: underline;
}

/** end hljs themes **/

.rco-wrap-hljs .hljs {
  white-space: pre-wrap;
}
